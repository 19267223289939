import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AppMob from './AppMob';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('quickLink')
);

ReactDOM.render(
  <React.StrictMode>
    <AppMob />
  </React.StrictMode>,
  document.getElementById('quickLinkMob')
);
