import React, { Component } from 'react';
import axios from 'axios';

function codeString(nn) {
  const t = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];
  let res = '';
  let n = nn;
  if (n < 62) {
    res = t[n];
  } else {
    while (n >= 61) {
      const o = n % 62;
      n = Math.floor(n / 62);
      res += t[o];
    }
    res += t[n];
  }
  return res
    .split('')
    .reverse()
    .join('');
}

const block = document.getElementById('quickLinkMob');
const lang = block.dataset.lang;
const corp = block.dataset.enterprise;

export default class LinkPayMob extends Component {
  state = {
    iValue: '',
    iValueCorp: '',
    check: 0,
  };

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  checkInput = () => {
    const { iValue } = this.state;
    if (
      Number.isInteger(Number(iValue)) &&
      Number(iValue) >= 1 &&
      Number(iValue) <= 999999999
    ) {
      return true;
    }
    return false;
  };

  checkInputPattern = () => {
    const { iValueCorp } = this.state;
    const regexp = /[0-9]{8,12}/;
    return regexp.test(iValueCorp);
  };

  checkInputCorp = () => {
    const { iValueCorp } = this.state;
    return `1${iValueCorp}`;
  };

  handleSubmit = e => {
    const { iValue, iValueCorp, check } = this.state;
    e.preventDefault();
    const enterpriseCode = Number(block.dataset.id);
    if (Number(check) === 0) {
      if (this.checkInput() === true) {
        axios({
          method: 'post',
          url: 'https://api2.gkh.in.ua/api/v1/helper/billpp',
          data: {
            a: iValue, 
            e: enterpriseCode
          },
        }).then(response => {
          try {
            // if (response.data && response.data.code) {
              const baseUrl = 'https://gkh.in.ua/p/';
              const param = `${codeString(enterpriseCode)}/${codeString(response.data.code)}/site`;
              window.open(`${baseUrl}${param}`, '_blank');
              console.log(`${baseUrl}${param}`);
            // }
            }
           catch (error) {
           console.log(error);
        }})
        .catch(error => {
         console.log(error)
        });
        return;
      }
      return;
    }

    if (Number(check) === 1 && this.checkInputPattern()) {
      const baseUrl = 'https://gkh.in.ua/e/';
      const param = `${codeString(enterpriseCode)}/${codeString(
        this.checkInputCorp(iValueCorp),
      )}/site`;
      window.open(`${baseUrl}${param}`, '_blank');
    }
  };

  render() {
    const { iValue, check, iValueCorp } = this.state;
    return (
      <form onSubmit={this.handleSubmit} className='wrapperQuickLink'>
        <div className='inputsWrapper'>
        {corp === 'true' && <div className='radioWrapper'>
            <label
              className='labelPrivat'
              htmlFor="radioPrivat1Mob"
            >
              <input
                onChange={this.handleChange}
                className='radioPrivat'
                id="radioPrivat1Mob"
                type="radio"
                value={0}
                name="check"
                checked={Number(check) === 0}
              />
              <span>{lang === 'ru' ? 'для населения' : 'для населення'}</span>
            </label>
            <label
              className='labelPrivat'
              htmlFor="radioPrivat2Mob"
            >
              <input
                onChange={this.handleChange}
                className='radioPrivat'
                id="radioPrivat2Mob"
                type="radio"
                name="check"
                value={1}
                checked={Number(check) === 1}
              />
              <span>{lang === 'ru' ? 'для организаций' :'для організацій'}</span>
            </label>
          </div>}
          {Number(check) === 0 ? (
            <input
              value={iValue}
              onChange={this.handleChange}
              placeholder={lang === 'ru' ? 'Введите лицевой счет' : 'Введіть особоивий рахунок'}
              className='input'
              type="number"
              minLength={7}
              maxLength={9}
              name="iValue"
              min={0}
              max={999999999}
              required
            />
          ) : (
            <input
              value={iValueCorp}
              onChange={this.handleChange}
              placeholder={lang === 'ru' ? 'Код ЕГРПОУ или ИНН' : 'Код ЄДРПОУ або ІНН'}
              className='input'
              type="text"
              minLength={8}
              maxLength={12}
              name="iValueCorp"
              pattern="[0-9]{8,12}"
              required
            />
          )}
        </div>
        <button
          type="submit"
          className='payClick'
          onClick={this.handleSubmit}
        >
          {lang === 'ru' ? 'Оплатить быстро' : 'Сплатити швидко'}
        </button>
      </form>
    );
  }
}
